
import { defineComponent, onMounted, ref } from 'vue';
import { useAdvertisements } from '@/composables/api';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    permissionUnits
  },
  setup() {
    const route = useRoute();

    const page = ref(1);
    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const { data, isLoading, isFetching } = useAdvertisements({ page, sortBy, descSortBy });
    const { t } = useI18n();

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    return {
      data,
      isLoading,
      isFetching,
      page,
      t,
      sortChange
    };
  }
});
